.app {
  text-align: center;
  line-height: 1.5em;
}

.app-logo {
  width: 100%;
  max-width: 200px;
}

.app-header {
  padding: 40px;
  align-items: center;
  font-size: calc(10px + 2vmin);
}

.app-body {
  text-align: left;
}

.heading {
  margin-top: 0;
}

.centered {
  text-align: center;
}

.app .button {
  color: #ffffff !important;
  background: #4364e9 !important;
  font-size: 1em !important;
  font-weight: bold !important;
  border: none !important;
  border-radius: 5px !important;
  padding: 10px 20px !important;
  cursor: pointer !important;
}

.app .button:hover {
  background: #13dfe0 !important;
}

.link {
  color: #13dfe0;
  font-size: 1em;
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  display: inline;
  margin: 0;
  padding: 0;
}

.app-link-img {
  height: 50px;
  margin: 5px;
}