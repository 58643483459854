.separator {
  height: 20px;
  border-bottom: 1px solid #d1d8de;
  margin: 20px 0 40px;
  text-align: center;
}

.separator-text {
  line-height: 40px;
  background-color: #ffffff;
  padding: 0 10px;
}
