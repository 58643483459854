@import url(https://fonts.googleapis.com/css?family=Nunito);
body, button {
  margin: 0;
  padding: 0;
  font-family: 'Nunito', sans-serif;
  color: #545454;
}

.loader,
.loader:before,
.loader:after {
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: load7 1.8s infinite ease-in-out;
  animation: load7 1.8s infinite ease-in-out;
}
.loader {
  color: #4364e9;
  font-size: 10px;
  margin: 80px auto;
  position: relative;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.loader:before,
.loader:after {
  content: '';
  position: absolute;
  top: 0;
}
.loader:before {
  left: -3.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loader:after {
  left: 3.5em;
}
@-webkit-keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
@keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}

.separator {
  height: 20px;
  border-bottom: 1px solid #d1d8de;
  margin: 20px 0 40px;
  text-align: center;
}

.separator-text {
  line-height: 40px;
  background-color: #ffffff;
  padding: 0 10px;
}

.react-phone-number-input__row
{
	/* This is done to stretch the contents of this component */
	display     : flex;
	align-items : center;
}

.react-phone-number-input__phone
{
	/* The phone number input stretches to fill all empty space */
	flex : 1 1;

	/* The phone number input should shrink
	   to make room for the extension input */
	min-width : 0;
}

.react-phone-number-input__icon
{
	/* The flag icon size is 4x3 hence the exact `width` and `height` values */
	width      : 1.24em;
	height     : 0.93em;

	/* `1px` is still too much for a "retina" screen but there's no way in CSS to specify "hairline" border width. */
	border     : 1px solid rgba(0, 0, 0, 0.5);

	/* Makes sure `width`x`height` is exactly `4x3` and `border` width is not included in it */
	box-sizing : content-box;
}

.react-phone-number-input__icon--international
{
	/* The international icon size is square */
	/* and also has no border hence the `2 * 1px` `width` and `height` compensation */
	width  : calc(0.93em + 2px);
	height : calc(0.93em + 2px);

	/* The international icon size is square hence the exact `padding` value */
	/* for precise alignment with `4x3` sized country flags. */
	padding-left  : 0.155em;
	padding-right : 0.155em;

	border : none;
}

.react-phone-number-input__error
{
	margin-left : calc(1.24em + 2px + 0.3em + 0.35em + 0.5em);
	margin-top  : calc(0.3rem);
	color       : #D30F00;
}

.react-phone-number-input__icon-image
{
	max-width  : 100%;
	max-height : 100%;
}

/* Removes `<input type="number"/>` up/down arrows in Webkit browsers. */
.react-phone-number-input__ext-input::-webkit-inner-spin-button,
.react-phone-number-input__ext-input::-webkit-outer-spin-button
{
	margin             : 0 !important;
	-webkit-appearance : none !important;
	-moz-appearance    : textfield !important;
}

.react-phone-number-input__ext-input
{
	width : 3em;
}

.react-phone-number-input__ext
{
	white-space: nowrap;
}

.react-phone-number-input__ext,
.react-phone-number-input__ext-input
{
	margin-left : 0.5em;
}

/* Styling native country `<select/>`. */

.react-phone-number-input__country--native
{
	position     : relative;
	align-self   : stretch;
	display      : flex;
	align-items  : center;
	margin-right : 0.5em;
}

.react-phone-number-input__country-select
{
	position : absolute;
	top      : 0;
	left     : 0;
	height   : 100%;
	width    : 100%;
	z-index  : 1;
	border   : 0;
	opacity  : 0;
	cursor   : pointer;
}

.react-phone-number-input__country-select-arrow
{
	display            : block;
	content            : '';
	width              : 0;
	height             : 0;
	margin-bottom      : 0.1em;
	margin-top         : 0.3em;
	margin-left        : 0.3em;
	border-width       : 0.35em 0.2em 0 0.2em;
	border-style       : solid;
	border-left-color  : transparent;
	border-right-color : transparent;
	color              : #B8BDC4;
	opacity            : 0.7;
	-webkit-transition         : color 0.1s;
	transition         : color 0.1s;
}

/* Something from stackoverflow. */
.react-phone-number-input__country-select-divider
{
	font-size  : 1px;
	background : black;
}

.react-phone-number-input__country-select:focus + .react-phone-number-input__country-select-arrow,
.react-phone-number-input__country.rrui__select--focus .rrui__select__arrow
{
	color : #03B2CB;
}

/* Styling phone number input */

.react-phone-number-input__input
{
	height        : calc(0.3rem * 6);
	outline       : none;
	border-radius : 0;
	padding       : 0;
	-webkit-appearance    : none;
	   -moz-appearance    : none;
	        appearance    : none;
	border        : none;
	border-bottom : 1px solid #C5D2E0;
	-webkit-transition    : border 0.1s;
	transition    : border 0.1s;
	font-size     : inherit;
}

.react-phone-number-input__input:focus
{
	border-color : #03B2CB;
}

.react-phone-number-input__input--disabled
{
	cursor : default;
}

.react-phone-number-input__input--invalid,
.react-phone-number-input__input--invalid:focus
{
	border-color : #EB2010;
}

/* Overrides Chrome autofill yellow background color */
.react-phone-number-input__input:-webkit-autofill
{
	box-shadow : 0 0 0 1000px white inset;
}

/* A small fix for `react-responsive-ui` */
.react-phone-number-input__country .rrui__select__button
{
	border-bottom : none;
}
.sign-up-button {
  display: block;
  max-width: 280px;
  width: 100%;
  background: none;
  border-radius: 5px;
  border: none;
  margin: 5px auto;
  padding: 13px 0 8px;
  cursor: pointer;
  outline: none;
}
.sign-up-button2 {
  width:240px;
  height:52px;
  max-width: 280px;
  background: none;
  border-radius: 40px;
  border: none;
  margin: 5px auto;
  padding: 13px 0 13px;
  cursor: pointer;
  outline: none;
  margin-left:20px !important;
  margin-right:20px !important;
}

.social-sign-up-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  vertical-align: top;
  -webkit-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
}

.xero-sign-up-icon {
  width: 26px;
  height: 27px;
  position: relative;
  top: -4px;
}

.social-sign-up-text {
  display: inline-block;
  vertical-align: top;
  font-size: 16px;
  font-weight: bold;
  line-height: 20px;
  margin-left: 5px;
  -webkit-transition: all .1s ease-in-out;
  transition: all .1s ease-in-out;
}

.sign-up-button:hover .social-sign-up-icon,
.sign-up-button:hover .social-sign-up-text {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  font-size: 20px;
}

.sign-up-button.google-sign-up {
  border: 1px solid #d1d8de;
}
.sign-up-button2.google-sign-up {
  border: 1px solid #d1d8de;
}

.sign-up-button.facebook-sign-up {
  background: #385898;
  color: #ffffff;
}

.sign-up-button.xero-sign-up {
  border: 1px solid #d1d8de;
  padding-top: 9px;
  padding-bottom: 3px;
}
.sign-up-button2.xero-sign-up {
  border: 1px solid #d1d8de;
  padding-top: 9px;
  padding-bottom: 3px;
}

.text-field {
  display: block !important;
  width: 100% !important;
  max-width: 260px !important;
  margin: 5px auto !important;
  border-radius: 5px !important;
  padding: 10px !important;
  border: 1px solid #d1d8de !important;
  height: 40px  !important;
  font-family: 'Nunito', sans-serif;
  font-size: 14px;
}

.disclaimer {
  margin: 1em 0;
  font-size: 12px;
}

.sign-up-form-title {
  margin: 18px 0 1em !important;
  font-size: 30px !important;
}

.react-phone-number-input__phone {
  font-family: 'Nunito', sans-serif;
  border: none;
}

.react-phone-number-input__icon {
  display: block;
}

.react-phone-number-input__icon-image {
  display: block;
}

.react-phone-number-input__row {
  height: 40px;
}

.Ccontainer {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.Ccontainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  margin-top:8px;
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  border-radius: 4px;
  border:1px solid #e1e1e5;
  background-color: #fff;
}

/* On mouse-over, add a grey background color */
.Ccontainer:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.Ccontainer input:checked ~ .checkmark {
  background-color: #2196F3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.Ccontainer input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.Ccontainer .checkmark:after {
  left: 7px;
  top: 3px;
  width: 7px;
  height: 12px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.alert-banner {
  background: #F0F3FE;
  border: solid 1px #4263EA;
  border-radius: 8px;
  width: 520px;
  padding: 16px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  grid-gap: 16px;
  gap: 16px;
  margin: 16px auto;
  box-sizing: border-box;
}

.alert-banner-text {
  text-align: left;
}

.alert-banner h2 {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: #272830;
  margin: 0;
  padding: 0;
}
.alert-banner p {
  margin: 0;
  padding: 0;
}
.sign-up {
  text-align: center;
  margin: 0 auto;
}
.AIPHalfWidth{
  width:100%;
}
.AIPTitle{
  color:#0A1E70;font-size:30px;font-weight:700;text-align:left;padding-left:25px;line-height:36px;padding-top:50px

}
.MobileTitle{
color:#0A1E70;font-size:20px !important;font-weight:700;text-align:left;padding-left:25px;line-height:22px;padding-top:10px

}
.sign-up ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.sign-up-error {
  color: #ff0000;
}

.sign-up-popup-close {
  border-radius: 25px;
  height: 50px;
  width: 50px;
  background: #4364e9;
  border: none;
  color: #ffffff;
  font-size: 25px;
  font-weight: bold;
  position: absolute;
  top: -25px;
  right: -25px;
  cursor: pointer;
  outline: none;
}

.sign-up-popup-close:hover {
  background: #13dfe0;
}

@media only screen and (max-width: 550px) {
  .sign-up-popup-close {
    top: 5px;
    right: 5px;
    width: 40px;
    height: 40px;
    line-height: 40px;
    font-size: 40px;
  }
}

.welcome-screen-heading {
  padding-top: 20px !important;
  font-size: 20px !important;
  font-weight: bold !important;
  color: #545454 !important;
}

.user-details p {
  font-size: 16px !important;
  color: #545454 !important;
}
.app {
  text-align: center;
  line-height: 1.5em;
}

.app-logo {
  width: 100%;
  max-width: 200px;
}

.app-header {
  padding: 40px;
  align-items: center;
  font-size: calc(10px + 2vmin);
}

.app-body {
  text-align: left;
}

.heading {
  margin-top: 0;
}

.centered {
  text-align: center;
}

.app .button {
  color: #ffffff !important;
  background: #4364e9 !important;
  font-size: 1em !important;
  font-weight: bold !important;
  border: none !important;
  border-radius: 5px !important;
  padding: 10px 20px !important;
  cursor: pointer !important;
}

.app .button:hover {
  background: #13dfe0 !important;
}

.link {
  color: #13dfe0;
  font-size: 1em;
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  display: inline;
  margin: 0;
  padding: 0;
}

.app-link-img {
  height: 50px;
  margin: 5px;
}
