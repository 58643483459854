.sign-up-button {
  display: block;
  max-width: 280px;
  width: 100%;
  background: none;
  border-radius: 5px;
  border: none;
  margin: 5px auto;
  padding: 13px 0 8px;
  cursor: pointer;
  outline: none;
}
.sign-up-button2 {
  width:240px;
  height:52px;
  max-width: 280px;
  background: none;
  border-radius: 40px;
  border: none;
  margin: 5px auto;
  padding: 13px 0 13px;
  cursor: pointer;
  outline: none;
  margin-left:20px !important;
  margin-right:20px !important;
}

.social-sign-up-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  vertical-align: top;
  transition: all .2s ease-in-out;
}

.xero-sign-up-icon {
  width: 26px;
  height: 27px;
  position: relative;
  top: -4px;
}

.social-sign-up-text {
  display: inline-block;
  vertical-align: top;
  font-size: 16px;
  font-weight: bold;
  line-height: 20px;
  margin-left: 5px;
  transition: all .1s ease-in-out;
}

.sign-up-button:hover .social-sign-up-icon,
.sign-up-button:hover .social-sign-up-text {
  transform: scale(1.1);
  font-size: 20px;
}

.sign-up-button.google-sign-up {
  border: 1px solid #d1d8de;
}
.sign-up-button2.google-sign-up {
  border: 1px solid #d1d8de;
}

.sign-up-button.facebook-sign-up {
  background: #385898;
  color: #ffffff;
}

.sign-up-button.xero-sign-up {
  border: 1px solid #d1d8de;
  padding-top: 9px;
  padding-bottom: 3px;
}
.sign-up-button2.xero-sign-up {
  border: 1px solid #d1d8de;
  padding-top: 9px;
  padding-bottom: 3px;
}

.text-field {
  display: block !important;
  width: 100% !important;
  max-width: 260px !important;
  margin: 5px auto !important;
  border-radius: 5px !important;
  padding: 10px !important;
  border: 1px solid #d1d8de !important;
  height: 40px  !important;
  font-family: 'Nunito', sans-serif;
  font-size: 14px;
}

.disclaimer {
  margin: 1em 0;
  font-size: 12px;
}

.sign-up-form-title {
  margin: 18px 0 1em !important;
  font-size: 30px !important;
}

.react-phone-number-input__phone {
  font-family: 'Nunito', sans-serif;
  border: none;
}

.react-phone-number-input__icon {
  display: block;
}

.react-phone-number-input__icon-image {
  display: block;
}

.react-phone-number-input__row {
  height: 40px;
}

.Ccontainer {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.Ccontainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  margin-top:8px;
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  border-radius: 4px;
  border:1px solid #e1e1e5;
  background-color: #fff;
}

/* On mouse-over, add a grey background color */
.Ccontainer:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.Ccontainer input:checked ~ .checkmark {
  background-color: #2196F3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.Ccontainer input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.Ccontainer .checkmark:after {
  left: 7px;
  top: 3px;
  width: 7px;
  height: 12px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.alert-banner {
  background: #F0F3FE;
  border: solid 1px #4263EA;
  border-radius: 8px;
  width: 520px;
  padding: 16px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 16px;
  margin: 16px auto;
  box-sizing: border-box;
}

.alert-banner-text {
  text-align: left;
}

.alert-banner h2 {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: #272830;
  margin: 0;
  padding: 0;
}
.alert-banner p {
  margin: 0;
  padding: 0;
}