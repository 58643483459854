.sign-up {
  text-align: center;
  margin: 0 auto;
}
.AIPHalfWidth{
  width:100%;
}
.AIPTitle{
  color:#0A1E70;font-size:30px;font-weight:700;text-align:left;padding-left:25px;line-height:36px;padding-top:50px

}
.MobileTitle{
color:#0A1E70;font-size:20px !important;font-weight:700;text-align:left;padding-left:25px;line-height:22px;padding-top:10px

}
.sign-up ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.sign-up-error {
  color: #ff0000;
}

.sign-up-popup-close {
  border-radius: 25px;
  height: 50px;
  width: 50px;
  background: #4364e9;
  border: none;
  color: #ffffff;
  font-size: 25px;
  font-weight: bold;
  position: absolute;
  top: -25px;
  right: -25px;
  cursor: pointer;
  outline: none;
}

.sign-up-popup-close:hover {
  background: #13dfe0;
}

@media only screen and (max-width: 550px) {
  .sign-up-popup-close {
    top: 5px;
    right: 5px;
    width: 40px;
    height: 40px;
    line-height: 40px;
    font-size: 40px;
  }
}

.welcome-screen-heading {
  padding-top: 20px !important;
  font-size: 20px !important;
  font-weight: bold !important;
  color: #545454 !important;
}

.user-details p {
  font-size: 16px !important;
  color: #545454 !important;
}